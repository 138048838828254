<template>
  <el-dialog :destroy-on-close="true" :title="title" :visible.sync="show" :lock-scroll="false" :before-close="dialogClose" :close-on-click-modal="false" width="520px">
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="产品名称" prop="name" :rules="{required: true, message: '产品名称不能为空', trigger: 'blur'}">
        <el-input style="width:300px;" placeholder="请输入产品名称" v-model="form.name" v-if="type"></el-input>
        <div class="content-text" v-else>{{form.name}}</div>
      </el-form-item>
      <el-form-item label="产品编码" prop="number" :rules="{required: true, message: '产品编码不能为空', trigger: 'blur'}">
        <el-input style="width:300px;" placeholder="请输入产品编码" v-model="form.number" v-if="type"></el-input>
        <div class="content-text" v-else>{{form.number}}</div>
      </el-form-item>
      <el-form-item label="供应商类型" prop="supplierType" :rules="{required: true, message: '供应商类型不能为空', trigger: 'change'}">
        <el-select v-model="form.supplierType" placeholder="请选择" style="width:300px;" v-if="type">
          <el-option v-for="(item,i) in supplierList" :key="i" :label="item" :value="i"></el-option>
        </el-select>
        <div class="content-text" v-else>{{supplierList[form.supplierType]}}</div>
      </el-form-item>

      <el-form-item label="产品原价" prop="originalPrice" :rules="{required: true, message: '产品原价不能为空', trigger: 'blur'}">
        <el-input v-model="form.originalPrice" oninput="value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" v-if="type" style="width:300px;" placeholder="请输入产品原价"></el-input>
        <div class="content-text" v-else>{{form.originalPrice}}</div>
      </el-form-item>

      <el-form-item label="油券类型" prop="oilCouponType">
        <el-select v-model="form.oilCouponType" placeholder="请选择" style="width:300px;" v-if="type">
          <el-option v-for="(item,i) in oilCouponTypeList" :key="i" :label="item" :value="i"></el-option>
        </el-select>
        <div class="content-text" v-else>{{oilCouponTypeList[form.oilCouponType]}}</div>
      </el-form-item>
      <el-form-item label="积分价格" prop="integralPrice" :rules="{required: true, message: '积分价格不能为空', trigger: 'blur'}">
        <el-input v-model="form.integralPrice" oninput="value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" v-if="type" style="width:300px;" placeholder="请输入积分价格"></el-input>
        <div class="content-text" v-else>{{form.integralPrice}}</div>
      </el-form-item>
      <el-form-item label="现金价格" prop="amountPrice" :rules="{required: true, message: '现金价格不能为空', trigger: 'blur'}">
        <el-input v-model="form.amountPrice" oninput="value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" v-if="type" style="width:300px;" placeholder="请输入现金价格"></el-input>
        <div class="content-text" v-else>{{form.amountPrice}}</div>
      </el-form-item>
      <el-form-item label="是否自定义">
        <div v-if="type">
          <el-radio v-model="form.isCustom" :label="0">否</el-radio>
          <el-radio v-model="form.isCustom" :label="1">是</el-radio>
        </div>
        <div class="content-text" v-else>{{form.isCustom?'是':'否'}}</div>
      </el-form-item>
      <el-form-item label="产品排序" prop="sort" :rules="{required: true, message: '产品排序不能为空', trigger: 'blur'}">
        <el-input style="width:300px;" placeholder="请输入产品排序" v-model.number="form.sort" v-if="type"></el-input>
        <div class="content-text" v-else>{{form.sort}}</div>
      </el-form-item>
      <el-button @click="dialogClose">关闭</el-button>
      <el-button type="primary" v-debounce="onSubmit" v-if="type">保存</el-button>
    </el-form>
  </el-dialog>
</template>
<script>
import { addProductAttributeConfig, updateProductAttributeConfig } from '@/api/appSetting'
import uploadFileOnly from '@/components/uploadFileOnly'
export default {
  name: 'AddProduct',
  props: ['show', 'row', 'type', 'productId'],
  components: { uploadFileOnly },
  data() {
    return {
      form: {
        name: '',//名称
        number: '',//编号
        integralPrice: '',//积分价格
        amountPrice: '',//现金价格
        originalPrice: '',//原价格
        productId: '',//产品id
        sort: '',//排序
        supplierType: '',//供应商类型(0.顶聚，1.变蛙)
        isCustom: 0,
        oilCouponType: '',//油券类型
      },
      supplierList: ['顶聚', '变蛙', '京东云', '微云话费', '微云电费', '福禄话费'],
      oilCouponTypeList: ['广东中石化', '河南中石化', '广东中石油'],
      isSupplier: false
    }
  },
  created() {
    console.log(this.type, '_______')
    Object.assign(this.form, this.row)
  },
  computed: {
    title(val) {
      let list = ['查看产品属性详情', '添加产品属性', '编辑产品属性']
      return list[this.type]
    }
  },
  watch: {
    'form.supplierType'(val) {
      if (val == 0) {
        this.isSupplier = true
      } else {
        this.isSupplier = false
      }
    }
  },
  methods: {
    dialogClose() {
      this.$emit('update:show', false)
    },
    onSubmit() {
      if (!this.$validate('form')) return
      if (this.isSupplier && (this.form.oilCouponType === '' || this.form.oilCouponType == null)) {
        this.$message.error('请选择油券类型')
        return
      }
      let form = JSON.parse(JSON.stringify(this.form));
      if (this.productId) {
        form.productId = this.productId
      }
      let success = res => {
        console.log(res)
        if (res.code != 200) {
          this.$message.error('保存失败')
          return
        }
        this.$message.success('保存成功')
        this.$emit('success')
        this.dialogClose()
      }
      if (form.id) {
        updateProductAttributeConfig(form).then(success)
      } else {
        addProductAttributeConfig(form).then(success)
      }
    },
  },
}
</script>
<style scoped>
.el-form-item {
  text-align: left;
}
.content-text {
  letter-spacing: 1px;
  color: #333333;
  margin: 0px 10px;
  font-size: 16px;
}
</style>