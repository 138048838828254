<template>
  <div>
    <el-form inline size="small">
      <el-form-item label="关键字">
        <el-input style="width:200px;" placeholder="请输入名称搜索" v-model="form.search"></el-input>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" icon="el-icon-plus" @click="showAdd(1)">新增产品</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" style="margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);" height="calc(100vh - 200px)" row-key="number" :tree-props="{children: 'productAttributeConfigVOList', hasChildren: 'hasChildren'}">
      <el-table-column label="产品名称" align="left" width="200px">
        <template slot-scope="scope">
          <el-button type="text" @click="!scope.row.productId?showAdd(0,scope.row):showAddConfig(0,scope.row)">{{scope.row.name}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="number" align="center" label="产品编码"></el-table-column>
      <el-table-column align="center" label="充值账号类型">
        <template slot-scope="scope">
          <span>{{accountTypeList[scope.row.accountType]}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mlabel" align="center" label="关联菜单"></el-table-column>
      <el-table-column prop="originalPrice" align="center" label="产品原价"></el-table-column>
      <el-table-column prop="integralPrice" align="center" label="积分价格"></el-table-column>
      <el-table-column prop="amountPrice" align="center" label="现金价格"></el-table-column>
      <el-table-column align="center" label="供应商" width="70">
        <template slot-scope="scope">
          <span>{{supplierList[scope.row.supplierType]}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="支付方式">
        <template slot-scope="scope">
          <p v-for="(item,index) in (scope.row.paymentMode?scope.row.paymentMode.split(','):[])" :key="index">{{rechargeModeList[item]}}</p>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" width="70">
        <template slot-scope="scope">
          <el-tag size="mini" :type="!scope.row.isDisable ? 'primary' : 'warning'" disable-transitions>{{!scope.row.isDisable ?'已启用':'已禁用'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" align="center" label="创建时间"></el-table-column>
      <el-table-column align="center" label="操作" width="280">
        <template slot-scope="scope">
          <div v-if="!scope.row.productId">
            <el-button type="text" icon="el-icon-edit" @click="showAdd(2,scope.row)">编辑</el-button>
            <el-button type="text" icon="el-icon-edit" @click="showAddConfig(1,scope.row)">添加产品属性</el-button>
            <el-button type="text" icon="el-icon-remove-outline" style="color:red" @click="setDisable(scope.row.id,scope.row.isDisable)" v-if="scope.row.isDisable == 0">禁用</el-button>
            <el-button type="text" icon="el-icon-remove-outline" @click="setDisable(scope.row.id,scope.row.isDisable)" v-if="scope.row.isDisable == 1">启用</el-button>
            <el-button type="text" icon="el-icon-delete" style="color:red" @click="deleteOne(scope.row.id)">删除</el-button>
          </div>
          <div v-else>
            <el-button type="text" icon="el-icon-edit" @click="showAddConfig(2,scope.row)">编辑</el-button>
            <el-button type="text" icon="el-icon-remove-outline" style="color:red" @click="setConfigDisable(scope.row.id,scope.row.isDisable,scope.row)" v-if="scope.row.isDisable == 0">禁用</el-button>
            <el-button type="text" icon="el-icon-remove-outline" @click="setConfigDisable(scope.row.id,scope.row.isDisable)" v-if="scope.row.isDisable == 1">启用</el-button>
            <el-button type="text" icon="el-icon-delete" style="color:red" @click="deleteConfigOne(scope.row.id)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="margin-top:10px;" layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total">
    </el-pagination>
    <AddProduct v-if="isAddDialog" :isAddDialog.sync="isAddDialog" :row="editItem" :type="editType" @success="getList"></AddProduct>
    <AddProductConfig v-if="showProductConfig" :show.sync="showProductConfig" :row="productAttribute" :productId="editItem.id" :type="editType" @success="getList"></AddProductConfig>
  </div>

</template>
<script>
import { getProductAttributeConfigPage, deleteProductAttributeConfig, isDisableProductConfig, getProductConfigPage, deleteProductConfig, isDisableProductAttributeConfig } from '@/api/appSetting'
import AddProduct from './components/AddProduct.vue'
import AddProductConfig from './components/AddProductConfig.vue'
export default {
  name: 'products',
  components: {
    AddProduct, AddProductConfig
  },
  data() {
    return {
      supplierList: ['顶聚', '变蛙', '京东云', '微云话费','微云电费','福禄话费'],
      rechargeModeList: ['积分支付', '微信支付', '组合支付'],
      accountTypeList: ['手机号', 'QQ号码'],
      // Dialog
      isAddDialog: false,
      showProductConfig: false,
      editItem: {},
      productAttribute: {},
      editType: '',            // 编辑状态 1是详情，2是编辑,''是添加
      // search
      form: {
        search: '', //搜索
        pageNum: 1,//分页页数
        pageSize: 10,//每页显示多少
      },
      // list
      list: [],
      total: 0,//分页总页数
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getProductConfigPage(this.form).then((res) => {
        if (res.code == 200) {
          this.total = res.data.total;
          this.list = res.data.records;
          // this.list.forEach(item => {
          //   item.hasChildren = true
          // });
        } else {
          this.$message.error({
            message: res.msg || res.error_msg,
            duration: 1500
          });
        }
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getList();
    },
    //产品配置
    // type == 0 查看详情 type == 1添加 type == 2 编辑
    showAdd(type, row) {
      this.editType = type;
      this.editItem = row;
      this.isAddDialog = true;
    },
    setDisable(id, type, index) {
      let param = {
        id
      }
      this.$confirm(Number(type) == 1 ? '是否启用该产品?' : '是否禁用该产品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        isDisableProductConfig(param)
          .then((response) => {
            if (response.code == 200) {
              this.getList();
            }
            this.$message({
              type: response.code == 200 ? 'success' : 'default',
              duration: 2000,
              message: response.msg || response.error_msg,
            });
          })
      });
    },
    deleteOne(id) {
      this.$confirm('是否删除该产品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteProductConfig({ id }).then(res => {
          if (res.code != 200) {
            this.$message.error('删除失败')
            return
          }
          this.$message.success('删除成功')
          this.getList();
        })
      });
    },
    //产品属性
    // type == 0 查看详情 type == 1添加 type == 2 编辑
    showAddConfig(type, row) {
      this.editType = type;
      if (type != 1) {
        this.editItem = {};
        this.productAttribute = row
      } else {
        this.editItem = row;
        this.productAttribute = {}
      }
      this.showProductConfig = true;
    },
    setConfigDisable(id, type, index) {
      console.log(index)
      let param = {
        id
      }
      this.$confirm(Number(type) == 1 ? '是否启用该产品?' : '是否禁用该产品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        isDisableProductAttributeConfig(param)
          .then((response) => {
            if (response.code == 200) {
              this.getList();
            }
            this.$message({
              type: response.code == 200 ? 'success' : 'default',
              duration: 2000,
              message: response.msg || response.error_msg,
            });
          })
      });
    },
    deleteConfigOne(id) {
      this.$confirm('是否删除该产品属性?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteProductAttributeConfig({ id }).then(res => {
          if (res.code != 200) {
            this.$message.error('删除失败')
            return
          }
          this.$message.success('删除成功')
          this.getList();
        })
      });
    },
  },
}
</script>