<template>
  <el-dialog :destroy-on-close="true" :title="title" :visible.sync="isAddDialog" :lock-scroll="false" :before-close="dialogClose" :close-on-click-modal="false" width="920px">
    <el-form ref="form" :model="form" label-width="120px" :inline="type">
      <el-form-item label="产品名称" prop="name" :rules="{required: true, message: '产品名称不能为空', trigger: 'blur'}">
        <el-input style="width:300px;" placeholder="请输入产品名称" v-model="form.name" v-if="type"></el-input>
        <div class="content-text" v-else>{{form.name}}</div>
      </el-form-item>
      <el-form-item label="产品编码" prop="number" :rules="{required: true, message: '产品编码不能为空', trigger: 'blur'}">
        <el-input style="width:300px;" placeholder="请输入产品编码" v-model="form.number" v-if="type"></el-input>
        <div class="content-text" v-else>{{form.number}}</div>
      </el-form-item>
      <el-form-item label="跳转路径" prop="path" :rules="{required: true, message: '跳转路径不能为空', trigger: 'blur'}">
        <el-input style="width:300px;" placeholder="请输入跳转路径" v-model="form.path" v-if="type"></el-input>
        <div class="content-text" v-else>{{form.path}}</div>
      </el-form-item>
      <el-form-item label="充值账号类型" prop="accountType" :rules="{required: true, message: '充值账号类型不能为空', trigger: 'change'}">
        <el-select v-model="form.accountType" placeholder="请选择" style="width:300px;" v-if="type">
          <el-option v-for="(item,i) in accountTypeList" :key="i" :label="item" :value="i"></el-option>
        </el-select>
        <div class="content-text" v-else>{{accountTypeList[form.accountType]}}</div>
      </el-form-item>
      <el-form-item label="支付方式" prop="paymentMode" :rules="{required: true, message: '支付方式不能为空', trigger: 'change'}">
        <el-select v-model="form.paymentMode" multiple placeholder="请选择" style="width:300px;" v-if="type">
          <el-option v-for="(item,i) in rechargeModeList" :key="i" :label="item" :value="String(i)"></el-option>
        </el-select>
        <div class="content-text" v-else><span v-for="item in form.paymentMode" style="margin-right:10px">{{rechargeModeList[item]}}</span></div>
      </el-form-item>
      <el-form-item label="产品排序" prop="sort" :rules="{required: true, message: '产品排序不能为空', trigger: 'blur'}">
        <el-input style="width:300px;" placeholder="请输入产品排序" v-model.number="form.sort" v-if="type"></el-input>
        <div class="content-text" v-else>{{form.sort}}</div>
      </el-form-item>
      <el-form-item label="积分充值折扣">
        <el-input style="width:300px;" placeholder="请输入积分充值折扣" v-model.number="form.integralDiscount" oninput="value=value.replace(/[^\d]/g,'')" v-if="type">
          <template slot="append">%</template>
        </el-input>
        <div class="content-text" v-else>{{form.integralDiscount}}%</div>
      </el-form-item>
      <el-form-item label="现金充值折扣">
        <el-input style="width:300px;" placeholder="请输入现金充值折扣" v-model.number="form.amountDiscount" oninput="value=value.replace(/[^\d]/g,'')" v-if="type">
          <template slot="append">%</template>
        </el-input>
        <div class="content-text" v-else>{{form.amountDiscount}}%</div>
      </el-form-item>
      <el-form-item label="产品宣传" prop="propaganda" class="propaganda" :rules="{required: true, message: '产品宣传不能为空', trigger: 'blur'}">
        <el-input type="textarea" style="width:500px;" :autosize="{ minRows: 5, maxRows: 10}" placeholder="请输入产品宣传" v-model="form.propaganda" v-if="type"></el-input>
        <div class="content-text propaganda" v-else>{{form.propaganda}}</div>
      </el-form-item>
      <el-form-item label="产品描述">
        <div id="notes" style="width:100%;height:330px;" v-if="type"></div>
        <div style="border:1px solid #eee;padding:10px;border-radius:10px;" class="content-text" v-else v-html="form.notes"></div>
      </el-form-item>
      <el-button @click="dialogClose">关闭</el-button>
      <el-button type="primary" v-debounce="onSubmit" v-if="type">保存</el-button>
    </el-form>
  </el-dialog>
</template>
<script>
import { addProductConfig, updateProductConfig, getMobileMenuList } from '@/api/appSetting'
import uploadFileOnly from '@/components/uploadFileOnly'
import { baseURL } from '@/utils/http';
import { getCookies } from '@/utils/utils'
import E from 'wangeditor'
var editor = null
export default {
  name: 'AddProduct',
  props: ['isAddDialog', 'row', 'type'],
  components: { uploadFileOnly },
  data() {
    return {
      form: {
        name: '',
        number: '',
        mid: '',
        sort: '',//排序
        accountType: 0,
        notes: '',
        paymentMode: '',
        integralDiscount: '',
        amountDiscount: '',
        path: '',
        propaganda: ''
      },
      accountTypeList: ['手机号', 'QQ号码'],
      rechargeModeList: ['积分支付', '微信支付', '组合支付']
    }
  },
  created() {
    console.log(this.type, '_______')
    Object.assign(this.form, this.row)
    this.form.paymentMode = this.form.paymentMode ? this.form.paymentMode.split(',') : []
  },
  mounted() {
    this.$nextTick(() => {
      if (this.type) {
        editor = new E('#notes');
        editor.config.height = 250;
        editor.config.uploadImgServer = baseURL + '/uploadFile/uploadFileTwo';
        editor.config.uploadFileName = 'mfile'

        let headers = {
          'x-token': getCookies('x-token')
        }
        editor.config.uploadImgHeaders = headers;

        editor.config.uploadImgMaxLength = 1
        editor.create();
        editor.txt.html(this.form.notes)
      }
    })
  },
  computed: {
    title(val) {
      let list = ['查看产品详情', '添加产品', '编辑产品']
      return list[this.type]
    }
  },
  methods: {
    dialogClose() {
      this.$emit('update:isAddDialog', false)
    },
    onSubmit() {
      if (!this.$validate('form')) return
      let form = JSON.parse(JSON.stringify(this.form));
      form.paymentMode = String(form.paymentMode)
      form.notes = editor.txt.html();
      let success = res => {
        console.log(res)
        if (res.code != 200) {
          this.$message.error('保存失败')
          return
        }
        this.$message.success('保存成功')
        this.$emit('success')
        this.dialogClose()
      }
      if (form.id) {
        updateProductConfig(form).then(success)
      } else {
        addProductConfig(form).then(success)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.el-form-item {
  text-align: left;
}
.content-text {
  letter-spacing: 1px;
  color: #333333;
  margin: 0px 10px;
  font-size: 16px;
}
::v-deep .propaganda .el-form-item__content {
  margin-left: 0 !important;
}
.propaganda {
  display: flex;
  margin-left: 10px;
}
</style>